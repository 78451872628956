import { cx, Argument } from '@/styled-system/css'
import { title, TitleVariantProps } from '@/styled-system/recipes'

export type TitleProps = {
  children: React.ReactNode | React.ReactNode[] | string | null | undefined
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  size?: TitleVariantProps['size']
  shadow?: 'sm' | 'md' | 'lg' | 'base'
  format?: TitleVariantProps['format']
  additionalStyles?: Argument
  resetStyles?: boolean
  theme?: TitleVariantProps['theme']
  className?: string
}

export const Title = ({
  children,
  tag = 'h2',
  size = 'heading3',
  theme = 'dark',
  format = 'logotype',
  resetStyles = false,
  additionalStyles,
  className,
  ...rest
}: TitleProps) => {
  if (!children) return null
  const Tag = tag || 'h2'
  return (
    <Tag
      className={cx(
        !resetStyles && title({ size, theme, format }),
        additionalStyles,
        className,
      )}
      {...rest}
    >
      {children}
    </Tag>
  )
}
