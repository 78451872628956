'use client'

import { useCallback, useState } from 'react'

import {
  AccordionPrimitiveRoot,
  AccordionPrimitiveContent,
  AccordionPrimitiveItem,
  AccordionPrimitiveTrigger,
} from '@/components/accordion/accordion-primitive'

import { MonthPicker } from '@/global/components/trip-search/custom/month-picker'
import { RefinementList } from '@/global/components/trip-search/custom/refinement-list'
import { RangeSlider } from '@/global/components/trip-search/custom/range-slider'
import { GuestRoomSelect } from '@/global/components/trip-search/filters/guest'
import { SidebarLayout } from '@/global/components/trip-search/filters/sidebar-layout'

import type { RoomType } from '@/types/algolia/trip-search'

type FacetValue = {
  name: string
  count: number
}

function AccordionItem({
  value,
  children,
}: Readonly<React.PropsWithChildren<{ value: string }>>) {
  return (
    <AccordionPrimitiveItem
      _first={{
        borderTop: 'none',
        pt: 'semantic-spacing-0',
      }}
      value={value}
    >
      <AccordionPrimitiveTrigger>{value}</AccordionPrimitiveTrigger>
      <AccordionPrimitiveContent forceMount>
        {children}
      </AccordionPrimitiveContent>
    </AccordionPrimitiveItem>
  )
}

export function SearchFilters({
  hidePriceRange,
  maxDate,
  minDate,
  roomType,
  setRoomType,
  setShowSlideNav,
  showSlideNav,
}: Readonly<{
  hidePriceRange: boolean
  maxDate: number
  minDate: number
  roomType: RoomType
  setRoomType: (roomType: RoomType) => void
  setShowSlideNav: (show: boolean) => void
  showSlideNav: boolean
}>) {
  const [openItems, setOpenItems] = useState([
    'Departing between',
    'Duration',
    'Guests',
    'Interests',
    'Price Per Person',
    'Promotions',
    'Regions',
    'Travel style',
  ])

  const handleValueChange = (values: string[]) => {
    setOpenItems(values)
  }

  const getLowestNumber = (label: string) => {
    const matches = label.match(/\d+/g)
    return matches ? parseInt(matches[0], 10) : Infinity
  }

  const sortDuration = useCallback((a: FacetValue, b: FacetValue) => {
    return getLowestNumber(a.name) - getLowestNumber(b.name)
  }, [])

  return (
    <SidebarLayout
      setShowSlideNav={setShowSlideNav}
      showSlideNav={showSlideNav}
    >
      <AccordionPrimitiveRoot
        type="multiple"
        size="sm"
        defaultValue={openItems}
        onValueChange={handleValueChange}
      >
        <AccordionItem value="Departing between">
          <MonthPicker attribute="date" min={minDate} max={maxDate} />
        </AccordionItem>

        <AccordionItem value="Guests">
          <GuestRoomSelect roomType={roomType} setRoomType={setRoomType} />
        </AccordionItem>

        {hidePriceRange ? null : (
          <AccordionItem value="Price Per Person">
            <RangeSlider
              attribute={
                roomType === 'single'
                  ? 'prices.single.price'
                  : 'prices.double.price'
              }
            />
          </AccordionItem>
        )}

        <AccordionItem value="Duration">
          <RefinementList attribute="durationGroup" sortBy={sortDuration} />
        </AccordionItem>

        <AccordionItem value="Regions">
          <RefinementList attribute="region" sortBy={['name']} />
        </AccordionItem>

        <AccordionItem value="Travel style">
          <RefinementList attribute="travelStyles" />
        </AccordionItem>

        <AccordionItem value="Interests">
          <RefinementList attribute="interests" sortBy={['name']} />
        </AccordionItem>

        <AccordionItem value="Promotions">
          <RefinementList attribute="promotions" sortBy={['name']} />
        </AccordionItem>
      </AccordionPrimitiveRoot>
    </SidebarLayout>
  )
}
