export const seasonSegmentRegex = /\/?([a-z]{3}-\d{2}-[a-z]{3}-\d{2})$/

export const dateMonthMap: Record<string, string> = {
  0: 'jan',
  1: 'feb',
  2: 'mar',
  3: 'apr',
  4: 'may',
  5: 'jun',
  6: 'jul',
  7: 'aug',
  8: 'sep',
  9: 'oct',
  10: 'nov',
  11: 'dec',
}

export const localeCurrencyMap: Record<string, string> = {
  'en-US': 'USD',
  'en-CA': 'CAD',
  'en-IE': 'EUR',
  'en-GB': 'GBP',
  'en-AU': 'AUD',
  'en-NZ': 'NZD',
}
