'use client'

import { MdKeyboardBackspace } from 'react-icons/md'

import { Button } from '@/components/button'
import {
  Card,
  CardContent,
  CardFooter,
  CardTitle,
  CardDescription,
} from '@/components/card'
import { Image } from '@/components/image'

import { ContentfulLivePreview } from '@contentful/live-preview'

import Link from '@/global/components/link'

import { useSite } from '@/providers/site'

import { css, cx } from '@/styled-system/css'
import { VisuallyHidden } from '@/styled-system/jsx'
import { tripCard } from '@/styled-system/recipes'

import { currencyFormatter } from '@/utility/currency'
import { warn } from '@/utility/logging'

import type { TripCardProps } from '@/types/components/trip-card'

const Price: React.FC<{
  priceFrom?: number | null
  priceTo?: number | null
  classNames?: {
    priceContainer: string
    priceLabel: string
    priceOriginal: string
    priceValue: string
  }
}> = ({ priceFrom, priceTo, classNames }) => {
  const { locale } = useSite()

  if (!priceFrom && !priceTo) return <a href="#">Register your interest</a>

  return (
    <div className={cx('price', classNames?.priceContainer)}>
      <span
        className={cx(
          css({
            display: 'inline-flex',
            alignItems: 'center',
            gap: 'semantic-spacing-6',
          }),
          classNames?.priceLabel,
        )}
      >
        From{' '}
        {priceFrom && (
          <small className={cx(classNames?.priceOriginal)}>
            {currencyFormatter(priceFrom, locale)}
          </small>
        )}
      </span>
      {priceTo && (
        <span className={cx(classNames?.priceValue)}>
          {currencyFormatter(priceTo, locale)} pp
        </span>
      )}
    </div>
  )
}

const Route = ({
  startDestination,
  endDestination,
}: {
  startDestination?: string | null
  endDestination?: string | null
}) => {
  if (startDestination === endDestination) {
    return `${startDestination} return`
  }
  return (
    <>
      {startDestination}
      {endDestination && (
        <MdKeyboardBackspace className={css({ transform: 'scaleX(-1)' })} />
      )}
      {endDestination}
    </>
  )
}

export const TripCard: React.FC<TripCardProps> = ({
  id,
  url,
  image,
  tag,
  duration,
  title,
  startDestination,
  endDestination,
  ctaLabel = 'View Tour',
  priceFrom,
  priceTo,
  variant = 'overlay',
  promo,
  journey,
}) => {
  const siteContext = useSite()
  const promoCss =
    siteContext.brand.code?.toUpperCase() === 'APT'
      ? { bg: 'rgba(0, 0, 0, 0.4)', backdropFilter: 'blur(7.5px)' }
      : { bg: 'semantic-color-tags-promo-a-background' }

  if (!image?.src) {
    warn('Image source is missing card will be hidden', {
      context: '<TripCard />',
      id,
    })

    return null
  }
  const interestUrl = '#'
  const hasPrice = priceFrom || priceTo
  const classes = tripCard({ variant })

  const content = (
    <>
      {url && (
        <Link
          className={css({ pos: 'absolute', w: 'full', h: 'full' })}
          href={url}
          target="_blank"
        >
          <VisuallyHidden>{ctaLabel}</VisuallyHidden>
        </Link>
      )}
      <Image className={classes.image} alt={image.alt} src={image.src} />
      {promo && variant === 'stacked' && (
        <span className={cx(classes.promo, css({ ...promoCss }))}>{promo}</span>
      )}
    </>
  )

  return (
    <Card
      className={cx('group', classes.root)}
      pos="relative"
      {...ContentfulLivePreview.getProps({ entryId: id, fieldId: 'title' })}
    >
      <CardContent className={classes.content}>
        {url ? (
          <Link href={url} title={`${title}`}>
            {content}
          </Link>
        ) : (
          content
        )}
      </CardContent>
      <CardFooter
        mt="semantic-spacing-16"
        px="semantic-spacing-16"
        pb="semantic-spacing-24"
        className={classes.footer}
      >
        <div
          className={css({
            display: 'inline-flex',
            gap: 'semantic-spacing-16',
            mb: 'semantic-spacing-10',
          })}
        >
          {tag && <span className={classes.tag}>{tag}</span>}
          {duration && (
            <span className={classes.duration}>{duration} DAYS</span>
          )}
        </div>
        <CardTitle mb="semantic-spacing-6" className={cx(classes.title)}>
          {title}
        </CardTitle>
        <CardDescription mt="0" className={classes.description}>
          {journey ? (
            journey
          ) : (
            <Route
              startDestination={startDestination}
              endDestination={endDestination}
            />
          )}
        </CardDescription>
        <div
          className={cx(
            classes.footerContent,
            !hasPrice &&
              css({
                alignItems: 'center',
              }),
          )}
        >
          {hasPrice ? (
            <Price
              priceFrom={priceFrom}
              priceTo={priceTo}
              classNames={classes}
            />
          ) : (
            <Link
              href={interestUrl}
              title="Register your interest"
              className={cx(classes.interestLink)}
            >
              Register your interest
            </Link>
          )}
          <Button
            as={Link}
            href={url}
            size="md"
            target="_blank"
            variant={variant === 'overlay' ? 'tertiary-reverse' : 'secondary'}
            className={cx(
              css({
                w: hasPrice ? '50%' : '40%',
                mb: hasPrice ? 'semantic-spacing-6' : '',
                lg: {
                  w: '50%',
                },
              }),
              classes.button,
            )}
          >
            {ctaLabel}
          </Button>
        </div>
      </CardFooter>
    </Card>
  )
}
