import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/select'

import type { RoomType } from '@/types/algolia/trip-search'

export function GuestRoomSelect({
  roomType,
  setRoomType,
}: Readonly<{
  roomType: RoomType
  setRoomType: (roomType: RoomType) => void
}>) {
  return (
    <Select
      variant="outline"
      w="auto"
      onValueChange={(value) => setRoomType(value as RoomType)}
      value={roomType}
    >
      <SelectTrigger>
        <SelectValue placeholder="Sort by" />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="double">2 guests, 1 room</SelectItem>
        <SelectItem value="single">1 guest, 1 room</SelectItem>
      </SelectContent>
    </Select>
  )
}
