import { styled, type HTMLStyledProps } from '@/styled-system/jsx'
import { label } from '@/styled-system/recipes'
import { Label as LabelPrimitive } from '@radix-ui/react-label'

/**
 * @example
 * ```jsx
 * import { Label } from '@/components/label';
 *
 * export const ExampleLabel = () => {
 *   return <Label htmlFor="input">Label</Label>;
 * };
 * ```
 */
export const Label = styled(LabelPrimitive, label)
export type LabelProps = HTMLStyledProps<typeof Label>
