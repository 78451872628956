'use client'

import { eachYearOfInterval, add } from 'date-fns'
import { useState } from 'react'

export function useCalandarDate() {
  const minDate = new Date()
  const maxDate = add(minDate, { years: 3 })

  const [startDate, setStartDate] = useState<Date>(new Date())
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [showDateFromCalendar, setShowDateFromCalendar] = useState(false)
  const [showDateToCalendar, setShowDateToCalendar] = useState(false)

  const yearsArray = eachYearOfInterval({
    start: minDate,
    end: maxDate,
  })

  const handleDateChange = (date: Date | null, forEndDate: boolean = false) => {
    if (date instanceof Date) {
      if (!forEndDate && endDate && date > endDate) {
        setEndDate(null)
      }

      if (forEndDate) {
        setEndDate(date)
        setShowDateToCalendar(false)

        return
      }

      setStartDate(date)
      setShowDateFromCalendar(false)

      return
    }
  }

  return {
    minDate,

    startDate,
    setStartDate,
    endDate,
    setEndDate,
    showDateFromCalendar,
    setShowDateFromCalendar,
    showDateToCalendar,
    setShowDateToCalendar,
    handleDateChange,
    yearsArray,
  }
}
