'use client'

import 'dotenv/config'

import { liteClient as algoliasearch } from 'algoliasearch/lite'

import { InstantSearch } from 'react-instantsearch'

import { Search } from '@/global/components/trip-search/search'

import { algolia } from '@/utility/environment'

const applicationId = algolia.applicationId
const searchApiKey = algolia.searchApiKey
const indexName = algolia.searchIndex.tripSearch

if (!applicationId || !searchApiKey) {
  throw new Error('Missing Algolia env configuration')
}

const searchClient = algoliasearch(applicationId, searchApiKey)

export default function TripSearch() {
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indexName}
      future={{ preserveSharedStateOnUnmount: true }}
      // TODO: ALU-734: Add routing here to handle apply filters
      // routing
    >
      <Search />
    </InstantSearch>
  )
}
