'use client'

import { cx } from '@/styled-system/css'
import { styled } from '@/styled-system/jsx'
import { slider } from '@/styled-system/recipes'

import * as SliderPrimitive from '@radix-ui/react-slider'

const BaseSlider = ({
  className,
  ...props
}: React.ComponentProps<typeof SliderPrimitive.Root>) => {
  const styles = slider()

  return (
    <SliderPrimitive.Root className={cx(styles.root, className)} {...props}>
      <SliderPrimitive.Track className={styles.track}>
        <SliderPrimitive.Range className={styles.range} />
      </SliderPrimitive.Track>
      <SliderPrimitive.Thumb className={styles.thumb} />
    </SliderPrimitive.Root>
  )
}

BaseSlider.displayName = SliderPrimitive.Root.displayName

export const Slider = styled(BaseSlider)
