'use client'

import { Box } from '@/styled-system/jsx'

import { useEffect } from 'react'
import { useSortBy, type UseSortByProps } from 'react-instantsearch'

import { Label } from '@/components/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/select'

import type { RoomType } from '@/types/algolia/trip-search'

type SortByProps = Omit<UseSortByProps, 'items'> & {
  baseIndexName: string
  roomType: RoomType
}

export function SortBy(props: Readonly<SortByProps>) {
  const { baseIndexName: indexName, roomType } = props

  const items = [
    {
      label: `Most Popular`,
      value: `${indexName}_featured_${roomType}`,
    },
    {
      label: `Price: Lowest to Highest`,
      value: `${indexName}_price_${roomType}_asc`,
    },
  ]

  const { currentRefinement, options, refine } = useSortBy({ ...props, items })

  useEffect(() => {
    const currentItemIndex = options.findIndex(
      (option) => option.value === currentRefinement,
    )
    const updatedSortValue =
      items[currentItemIndex === -1 ? 0 : currentItemIndex].value

    refine(updatedSortValue)
  }, [roomType])

  return (
    <Box
      flex={1}
      pt={{ base: 'semantic-spacing-16', md: 'semantic-spacing-0' }}
    >
      <Label
        color="semantic-color-typography-headings-solid"
        textStyle="semantic-text-md-font-regular"
        htmlFor="sort-by"
      >
        Sort by
      </Label>
      <Select
        defaultValue={items[0].value}
        value={currentRefinement}
        onValueChange={(value) => refine(value)}
        variant="outline"
      >
        <SelectTrigger
          id="sort-by"
          mt="semantic-spacing-8"
          w={{ base: 'full', md: 256 }}
        >
          <SelectValue placeholder="Sort by" />
        </SelectTrigger>
        <SelectContent>
          {items.map((item) => (
            <SelectItem key={item.value} value={item.value}>
              {item.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </Box>
  )
}
