import { Flex } from '@/styled-system/jsx'

import React from 'react'
import { MdOutlineChevronLeft, MdOutlineChevronRight } from 'react-icons/md'
import { usePagination, type UsePaginationProps } from 'react-instantsearch'

import { Button } from '@/components/button'

export function Pagination(props: Readonly<UsePaginationProps>) {
  const {
    pages,
    currentRefinement,

    isFirstPage,
    isLastPage,
    refine,
    createURL,
  } = usePagination(props)

  if (pages.length <= 1) {
    return null
  }
  const previousPageIndex = currentRefinement - 1
  const nextPageIndex = currentRefinement + 1

  return (
    <Flex p="semantic-spacing-16" w="full" justifyContent="space-between">
      <PrevNextBtn
        disabled={isFirstPage}
        href={createURL(previousPageIndex)}
        icon={<MdOutlineChevronLeft size={16} />}
        onClick={() => refine(previousPageIndex)}
      />

      <Flex gap="semantic-spacing-4">
        {pages.map((page) => {
          const label = page + 1

          return (
            <PaginationItem
              active={page === currentRefinement}
              aria-label={`Page ${label}`}
              href={createURL(page)}
              icon={<>{label}</>}
              key={page}
              onClick={() => refine(page)}
            />
          )
        })}
      </Flex>

      <PrevNextBtn
        disabled={isLastPage}
        href={createURL(nextPageIndex)}
        icon={<MdOutlineChevronRight size={16} />}
        onClick={() => refine(nextPageIndex)}
      />
    </Flex>
  )
}

type PaginationItemProps = Omit<React.ComponentProps<'a'>, 'onClick'> & {
  onClick: NonNullable<React.ComponentProps<'a'>['onClick']>
  disabled: boolean

  active: boolean
  icon: React.ReactNode
}

function PaginationItem({
  active,
  onClick,
  ...props
}: Omit<PaginationItemProps, 'disabled'>) {
  const handleOnClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (isModifierClick(event)) {
      return
    }
    event.preventDefault()
    onClick(event)
  }

  return active ? (
    <Button
      css={{
        textStyle: 'semantic-text-md-font-regular',
        bg: 'semantic-bg-default',
        color: 'semantic-color-typography-body-solid',
        w: 'semantic-sizing-32',
      }}
      disabled
      icon={props.icon}
      size="sm"
      variant="ghost"
    />
  ) : (
    <Button
      css={{
        textStyle: 'semantic-text-md-font-regular-underline',
        w: 'semantic-sizing-32',
      }}
      size="sm"
      variant="ghost"
      as="a"
      onClick={handleOnClick}
      {...props}
    />
  )
}

function isModifierClick(event: React.MouseEvent<HTMLAnchorElement>) {
  const isMiddleClick = event.button === 1

  return Boolean(
    isMiddleClick ||
      event.altKey ||
      event.ctrlKey ||
      event.metaKey ||
      event.shiftKey,
  )
}

function PrevNextBtn({
  disabled,
  onClick,
  ...props
}: Omit<PaginationItemProps, 'label' | 'active'>) {
  return disabled ? (
    <Button disabled icon={props.icon} size="sm" />
  ) : (
    <Button as="a" size="sm" onClick={onClick} {...props} />
  )
}
