'use client'

import * as React from 'react'
import { MdCheck, MdExpandMore } from 'react-icons/md'

import * as SelectPrimitive from '@radix-ui/react-select'

import { styled } from '@/styled-system/jsx'
import { select } from '@/styled-system/recipes'

import { createStyleContext } from '@/theme/support/style-context'

const { withProvider, withContext } = createStyleContext(select)

const Trigger = ({
  ref,
  children,
  ...props
}: React.ComponentProps<typeof SelectPrimitive.Trigger>) => (
  <SelectPrimitive.Trigger ref={ref} {...props}>
    {children}
    <SelectPrimitive.Icon asChild>
      <MdExpandMore />
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
)
Trigger.displayName = SelectPrimitive.Trigger.displayName

const Viewport = withContext(SelectPrimitive.Viewport, 'viewport')

const Content = ({
  ref,
  children,
  position = 'popper',
  ...props
}: React.ComponentProps<typeof SelectPrimitive.Content>) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      position={position}
      data-position={position}
      {...props}
    >
      <Viewport data-position={position}>{children}</Viewport>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
)
Content.displayName = SelectPrimitive.Content.displayName

const ItemIndicator = withContext(
  styled(SelectPrimitive.ItemIndicator),
  'itemIndicator',
)

const Item = ({
  ref,
  children,
  ...props
}: React.ComponentProps<typeof SelectPrimitive.Item>) => (
  <SelectPrimitive.Item ref={ref} {...props}>
    <ItemIndicator>
      <MdCheck />
    </ItemIndicator>
    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
)
Item.displayName = SelectPrimitive.Item.displayName

/**
 * @example
 * ```jsx
 * import {
 *   Select,
 *   SelectContent,
 *   SelectGroup,
 *   SelectItem,
 *   SelectLabel,
 *   SelectTrigger,
 *   SelectValue,
 * } from '@/components/select'
 *
 * export const ExampleSelect = () => {
 *   return (
 *     <Select>
 *       <SelectTrigger w="180px">
 *         <SelectValue placeholder="Select a fruit" />
 *       </SelectTrigger>
 *       <SelectContent>
 *         <SelectGroup>
 *           <SelectLabel>Fruits</SelectLabel>
 *           <SelectItem value="apple">Apple</SelectItem>
 *           <SelectItem value="banana">Banana</SelectItem>
 *           <SelectItem value="blueberry">Blueberry</SelectItem>
 *           <SelectItem value="grapes">Grapes</SelectItem>
 *           <SelectItem value="pineapple">Pineapple</SelectItem>
 *         </SelectGroup>
 *       </SelectContent>
 *     </Select>
 *   );
 * };
 */
export const Select = withProvider(styled(SelectPrimitive.Root), 'root')

/**
 * @example
 * ```jsx
 * import { SelectTrigger, SelectValue } from '@/components/select';
 *
 * export const ExampleSelectTrigger = () => {
 *   return (
 *     <SelectTrigger w="180px">
 *       <SelectValue placeholder="Select a fruit" />
 *     </SelectTrigger>
 *   );
 * };
 * ```
 */
export const SelectTrigger = withContext(styled(Trigger), 'trigger')

/**
 * @example
 * ```jsx
 * import { SelectValue } from '@/components/select';
 *
 * export const ExampleSelectValue = () => {
 *   return (
 *     <SelectValue placeholder="Select a fruit" />
 *   );
 * };
 * ```
 */
export const SelectValue = withContext(styled(SelectPrimitive.Value), 'value')

/**
 * @example
 * ```jsx
 * import { SelectContent, SelectGroup, SelectLabel, SelectItem, SelectSeparator } from '@/components/select';
 *
 * export const ExampleSelectContent = () => {
 *   return (
 *     <SelectContent>
 *       <SelectGroup>
 *         <SelectLabel>Fruits</SelectLabel>
 *         <SelectItem value="apple">Apple</SelectItem>
 *         <SelectItem value="banana">Banana</SelectItem>
 *         <SelectItem value="blueberry">Blueberry</SelectItem>
 *         <SelectItem value="grapes">Grapes</SelectItem>
 *         <SelectItem value="pineapple">Pineapple</SelectItem>
 *       </SelectGroup>
 *       <SelectSeparator />
 *       <SelectGroup>
 *         <SelectLabel>Vegetables</SelectLabel>
 *         <SelectItem value="carrot">Carrot</SelectItem>
 *         <SelectItem value="potato">Potato</SelectItem>
 *       </SelectGroup>
 *     </SelectContent>
 *   );
 * };
 * ```
 */

export const SelectContent = withContext(styled(Content), 'content')

/**
 * @example
 * ```jsx
 * import { SelectGroup, SelectLabel, SelectItem } from '@/components/select';
 *
 * export const ExampleSelectGroup = () => {
 *   return (
 *     <SelectGroup>
 *       <SelectLabel>Fruits</SelectLabel>
 *       <SelectItem value="apple">Apple</SelectItem>
 *       <SelectItem value="banana">Banana</SelectItem>
 *     </SelectGroup>
 *   );
 * };
 * ```
 */
export const SelectGroup = withContext(styled(SelectPrimitive.Group), 'group')

/**
 * @example
 * ```jsx
 * import { SelectLabel } from '@/components/select';
 *
 * export const ExampleSelectLabel = () => {
 *   return (
 *     <SelectLabel value="apple">Apple</SelectLabel>
 *   );
 * };
 * ```
 */
export const SelectLabel = withContext(styled(SelectPrimitive.Label), 'label')

/**
 * @example
 * ```jsx
 * import { SelectItem } from '@/components/select';
 *
 * export const ExampleSelectItem = () => {
 *   return (
 *     <SelectItem value="apple">Apple</SelectItem>
 *   );
 * };
 * ```
 */
export const SelectItem = withContext(styled(Item), 'item')

/**
 * @example
 * ```jsx
 * import { SelectSeparator } from '@/components/select';
 *
 * export const ExampleSelectSeparator = () => {
 *   return (
 *     <SelectSeparator />
 *   );
 * };
 * ```
 */
export const SelectSeparator = withContext(
  styled(SelectPrimitive.Separator),
  'separator',
)
