import React from 'react'

import { MdExpandLess, MdExpandMore } from 'react-icons/md'

import { Button } from '@/components/button'

import styles from './month-picker.module.css'

type CustomDropdownProps = React.ComponentProps<'button'> & {
  date: string | null
  label: string
  showCalendar: boolean
}

export function CustomDropdown({
  date,
  label,
  onClick,
  showCalendar,
}: Readonly<CustomDropdownProps>) {
  return (
    <Button
      className={styles.dropdown}
      css={{
        textStyle: 'semantic-text-sm-font-regular',
        w: 'full',
        whiteSpace: 'nowrap',
        pl: 'semantic-spacing-12',
        pr: 'semantic-spacing-8',
      }}
      variant="unset"
      onClick={onClick}
    >
      {date ? date : label}
      {showCalendar ? <MdExpandLess size={20} /> : <MdExpandMore size={20} />}
    </Button>
  )
}
