'use client'

import { useInstantSearch } from 'react-instantsearch'

import { Alert, AlertTitle, AlertDescription } from '@/components/alert'

export function NoResultsBoundary({
  children,
  fallback,
}: Readonly<
  React.PropsWithChildren<{
    fallback: React.JSX.Element
  }>
>) {
  const { results } = useInstantSearch()

  // The `__isArtificial` flag makes sure not to display the No Results message
  // when no hits have been returned.
  if (!results.__isArtificial && results.nbHits === 0) {
    return (
      <>
        {fallback}
        <div hidden>{children}</div>
      </>
    )
  }

  return children
}

export function NoResults() {
  return (
    <Alert>
      <AlertTitle>There are no results available</AlertTitle>
      <AlertDescription>
        Try searching again using and updating different filters
      </AlertDescription>
    </Alert>
  )
}
