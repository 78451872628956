'use client'

import * as React from 'react';
import { MdExpandMore } from 'react-icons/md';

import { styled } from '@/styled-system/jsx';
import { accordion as accordionRecipe } from '@/styled-system/recipes';
import { createStyleContext } from '@/theme/support/style-context';
import * as AccordionPrimitive from '@radix-ui/react-accordion';

const { withProvider, withContext } = createStyleContext(accordionRecipe)

const Header = withContext(styled(AccordionPrimitive.Header), 'header')

const Trigger = ({
  children,
  ref,
  ...props
}: React.ComponentProps<typeof AccordionPrimitive.Trigger>) => (
  <Header>
    <AccordionPrimitive.Trigger ref={ref} {...props}>
      {children}
      <MdExpandMore />
    </AccordionPrimitive.Trigger>
  </Header>
)

Trigger.displayName = AccordionPrimitive.Trigger.displayName

const Content = ({
  children,
  ref,
  ...props
}: React.ComponentProps<typeof AccordionPrimitive.Content>) => (
  <AccordionPrimitive.Content ref={ref} {...props}>
    {children}
  </AccordionPrimitive.Content>
)
Content.displayName = AccordionPrimitive.Content.displayName

/**
 * @example
 * ```jsx
 * import {
 *   Accordion,
 *   AccordionContent,
 *   AccordionItem,
 *   AccordionTrigger,
 * } from '@/components/accordion';
 *
 * // For multiple opened accordion items pass defaultValue={['item-1','item-3']}
 *
 * export const ExampleAccordion = () => {
 *   return (
 *     <Accordion type="multiple" defaultValue={['item-1','item-3']} collapsible w="full">
 *       <AccordionItem value="item-1">
 *         <AccordionTrigger>Is it accessible?</AccordionTrigger>
 *         <AccordionContent>
 *           Yes. It adheres to the WAI-ARIA design pattern.
 *         </AccordionContent>
 *       </AccordionItem>
 *       <AccordionItem value="item-2">
 *         <AccordionTrigger>Is it styled?</AccordionTrigger>
 *         <AccordionContent>
 *           Yes. It comes with default styles that match the other
 *           components' aesthetic.
 *         </AccordionContent>
 *       </AccordionItem>
 *       <AccordionItem value="item-3">
 *         <AccordionTrigger>Is it animated?</AccordionTrigger>
 *         <AccordionContent>
 *           Yes. It's animated by default, but you can disable it if you
 *           prefer.
 *         </AccordionContent>
 *       </AccordionItem>
 *     </Accordion>
 *   );
 * };
 * ```
 */
const AccordionPrimitiveRoot = withProvider(
  styled(AccordionPrimitive.Root),
  'root',
)

/**
 * @example
 * ```jsx
 * import {
 *   AccordionItem,
 * } from '@/components/accordion';
 *
 * export const ExampleAccordionItem = () => {
 *   return (
 *     <AccordionItem value="item-1">
 *       <AccordionTrigger>Is it accessible?</AccordionTrigger>
 *       <AccordionContent>
 *         Yes. It adheres to the WAI-ARIA design pattern.
 *       </AccordionContent>
 *     </AccordionItem>
 *   );
 * };
 * ```
 */
const AccordionPrimitiveItem = withContext(
  styled(AccordionPrimitive.Item),
  'item',
)

/**
 * @example
 * ```jsx
 * import {
 *   AccordionTrigger,
 * } from '@/components/accordion';
 *
 * export const ExampleAccordionTrigger = () => {
 *   return (
 *     <AccordionTrigger>Is it accessible?</AccordionTrigger>
 *   );
 * };
 * ```
 */
const AccordionPrimitiveTrigger = withContext(styled(Trigger), 'trigger')

/**
 * @example
 * ```jsx
 * import {
 *   AccordionContent,
 * } from '@/components/accordion';
 *
 * export const ExampleAccordionContent = () => {
 *   return (
 *     <AccordionContent>
 *       Yes. It adheres to the WAI-ARIA design pattern.
 *     </AccordionContent>
 *   );
 * };
 * ```
 */
const AccordionPrimitiveContent = withContext(styled(Content), 'content')

export {
  AccordionPrimitiveRoot,
  AccordionPrimitiveItem,
  AccordionPrimitiveTrigger,
  AccordionPrimitiveContent,
}
