'use client'
import { cx } from '@/styled-system/css'
import { styled } from '@/styled-system/jsx'
import { checkbox } from '@/styled-system/recipes'

import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { MdCheck } from 'react-icons/md'

const BaseCheckbox = ({
  className,
  ...props
}: React.ComponentProps<typeof CheckboxPrimitive.Root>) => {
  const styles = checkbox()

  return (
    <CheckboxPrimitive.Root
      className={cx('peer', styles.root, className)}
      {...props}
    >
      <CheckboxPrimitive.Indicator className={styles.indicator}>
        <MdCheck className="checked-icon" />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  )
}

BaseCheckbox.displayName = CheckboxPrimitive.Root.displayName

export const Checkbox = styled(BaseCheckbox)
