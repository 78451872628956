import * as React from 'react'

import { styled } from '@/styled-system/jsx'
import { alert, alertTitle, alertDescription } from '@/styled-system/recipes'

const BaseAlert = (props: React.ComponentProps<'div'>) => (
  <div {...props} role="alert" />
)

/**
 * @example
 * ```jsx
 * import { Alert, AlertTitle, AlertDescription } from '@/components/alert';
 *
 * export const ExampleAlert = () => {
 *   return (
 *     <Alert>
 *       <AlertTitle>Alert Title</AlertTitle>
 *       <AlertDescription>Alert Description</AlertDescription>
 *     </Alert>
 *   );
 * };
 * ```
 */
export const Alert = styled(BaseAlert, alert)

/**
 * @example
 * ```jsx
 * import { AlertTitle } from '@/components/alert';
 *
 * export const ExampleAlertTitle = () => {
 *   return (
 *     <AlertTitle>Alert Title</AlertTitle>
 *   );
 * };
 * ```
 */
export const AlertTitle = styled('h5', alertTitle)

/**
 * @example
 * ```jsx
 * import { AlertDescription } from '@/components/alert';
 *
 * export const ExampleAlertDescription = () => {
 *   return (
 *     <AlertDescription>Alert Description</AlertDescription>
 *   );
 * };
 * ```
 */
export const AlertDescription = styled('div', alertDescription)
