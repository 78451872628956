import { css } from '@/styled-system/css'
import { Flex, Section } from '@/styled-system/jsx'

import { MdOutlineFilterAlt } from 'react-icons/md'
import { useInstantSearch } from 'react-instantsearch'

import { Title } from '@/components/title'
import { Button } from '@/components/button'

import { SortBy } from '@/global/components/trip-search/custom/sort-by'

import { algolia } from '@/utility/environment'

import type { RoomType } from '@/types/algolia/trip-search'

export function SearchResultHeader({
  roomType,
  setShowSlideNav,
}: Readonly<{
  roomType: RoomType
  setShowSlideNav: (show: boolean) => void
}>) {
  const { results } = useInstantSearch()
  const numHits = results.nbHits

  return (
    <Section
      alignItems={{ lg: 'center' }}
      bg="semantic-bg-default"
      display="flex"
      flexDir={{ base: 'column', md: 'row' }}
      justifyContent="space-between"
      pb={{
        base: 'semantic-spacing-24',
        md: 'semantic-spacing-32',
      }}
    >
      <Title
        className={css({
          textStyle: {
            base: 'semantic-heading-standard-md',
            lg: 'semantic-heading-standard-lg',
          },
        })}
        tag="h1"
        resetStyles
      >
        {numHits === 1 ? '1 tour found' : `${numHits} tours found`}
      </Title>

      <Flex alignItems="flex-end">
        <SortBy
          baseIndexName={algolia.searchIndex.tripSearch ?? 'trips'}
          roomType={roomType}
        />

        <Button
          css={{ ml: 'semantic-spacing-16', hideFrom: 'lg' }}
          leftIcon={<MdOutlineFilterAlt />}
          size="md"
          onClick={() => setShowSlideNav(true)}
        >
          Filters
        </Button>
      </Flex>
    </Section>
  )
}
