import { localeCurrencyMap } from '@/utility/constants'

export function currencyFormatter(amount: number, locale: string) {
  return Intl.NumberFormat(locale, {
    style: 'currency',
    // TODO: Get this from the locale config
    currency: localeCurrencyMap[locale],
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount)
}
