'use client'

import { Flex } from '@/styled-system/jsx'

import { useEffect } from 'react'
import { useRange, UseRangeProps } from 'react-instantsearch'

import { formatDateYearMonth } from '@/utility/algolia/format-search'

import { Calendar } from '@/global/components/trip-search/custom/month-picker/calendar'
import { useCalandarDate } from '@/global/components/trip-search/custom/month-picker/useCalendarDate'

export function MonthPicker(props: Readonly<UseRangeProps>) {
  const { start, refine } = useRange(props)

  const {
    minDate,

    startDate,
    setStartDate,

    endDate,
    setEndDate,

    showDateFromCalendar,
    setShowDateFromCalendar,

    showDateToCalendar,
    setShowDateToCalendar,

    handleDateChange,

    yearsArray,
  } = useCalandarDate()

  // Refine the date range when the start and end date are selected
  useEffect(() => {
    const formatStartDate = startDate
      ? Number(formatDateYearMonth(startDate))
      : undefined

    const formatEndDate = endDate
      ? Number(formatDateYearMonth(endDate))
      : undefined

    refine([formatStartDate, formatEndDate])
  }, [startDate, endDate])

  // Reset the start and end date
  useEffect(() => {
    const [startDate, endDate] = start

    if (startDate === -Infinity) {
      setStartDate(minDate)
    }

    if (endDate === Infinity) {
      setEndDate(null)
    }
  }, [start])

  return (
    <Flex
      alignItems="center"
      flexDir="row"
      gap="semantic-spacing-8"
      pos="relative"
      textStyle="semantic-text-xs-font-regular"
    >
      <Calendar
        handleDateChange={(date) => handleDateChange(date, false)}
        label="From"
        minDate={minDate}
        placement="left"
        selectedDate={startDate}
        setShowCalendar={setShowDateFromCalendar}
        showCalendar={showDateFromCalendar}
        yearsArray={yearsArray}
      />
      and
      <Calendar
        handleDateChange={(date) => handleDateChange(date, true)}
        label="To"
        minDate={startDate}
        placement="right"
        selectedDate={endDate}
        setShowCalendar={setShowDateToCalendar}
        showCalendar={showDateToCalendar}
        yearsArray={yearsArray}
      />
    </Flex>
  )
}
