import { styled } from '@/styled-system/jsx'
import {
  card,
  cardHeader,
  cardTitle,
  cardDescription,
  cardContent,
  cardFooter,
} from '@/styled-system/recipes'

/**
 * @example
 * ```jsx
 * import {
 *   Card,
 *   CardHeader,
 *   CardTitle,
 *   CardDescription,
 *   CardContent,
 *   CardFooter,
 * } from '@/components/card';
 *
 * export const ExampleCard = () => {
 *   return (
 *     <Card>
 *       <CardHeader>
 *         <CardTitle>Card Title</CardTitle>
 *         <CardDescription>Card Description</CardDescription>
 *       </CardHeader>
 *       <CardContent>
 *         <p>Card Content</p>
 *       </CardContent>
 *       <CardFooter>Card Footer</CardFooter>
 *     </Card>
 *   );
 * };
 * ```
 */
export const Card = styled('div', card)

/**
 * @example
 *
 * ```jsx
 * import {
 *   CardHeader,
 *   CardTitle,
 *   CardDescription,
 * } from '@/components/card';
 *
 * export const ExampleCardHeader = () => {
 *   return (
 *      <CardHeader>
 *        <CardTitle>Card Title</CardTitle>
 *        <CardDescription>Card Description</CardDescription>
 *      </CardHeader>
 *   );
 * };
 * ```
 */
export const CardHeader = styled('div', cardHeader)

/**
 * @example
 *
 * ```jsx
 * import { CardTitle } from '@/components/card';
 *
 * export const ExampleCardTitle = () => {
 *   return (
 *      <CardTitle>Card Title</CardTitle>
 *   );
 * };
 * ```
 */
export const CardTitle = styled('h3', cardTitle)

/**
 * @example
 * ```jsx
 * import { CardDescription } from '@/components/card';
 *
 * export const ExampleCardDescription = () => {
 *   return (
 *     <CardDescription>Card Description</CardDescription>
 *   );
 * };
 * ```
 */
export const CardDescription = styled('div', cardDescription)

/**
 * @example
 * ```jsx
 * import { CardContent } from '@/components/card';
 *
 * export const ExampleCardContent = () => {
 *   return (
 *     <CardContent>Card Content</CardContent>
 *   );
 * };
 * ```
 */
export const CardContent = styled('div', cardContent)

/**
 * @example
 * ```jsx
 * import { CardFooter } from '@/components/card';
 *
 * export const ExampleCardFooter = () => {
 *   return (
 *     <CardFooter>Card Footer</CardFooter>
 *   );
 * };
 * ```
 */
export const CardFooter = styled('div', cardFooter)
