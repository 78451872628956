import { css } from '@/styled-system/css'

import {
  useRefinementList,
  type UseRefinementListProps,
} from 'react-instantsearch'

import { Checkbox } from '@/components/checkbox'
import { Label } from '@/components/label'

export function RefinementList(props: Readonly<UseRefinementListProps>) {
  const { items, refine, canRefine } = useRefinementList(props)

  return (
    <ul
      className={css({
        '& > li': {
          '&:not(:last-child)': {
            mb: 'semantic-spacing-16',
          },
        },
      })}
    >
      {!canRefine && (
        <li
          className={css({
            textStyle: 'semantic-text-sm-font-regular',
          })}
        >
          No matching filters
        </li>
      )}

      {items.map((item) => (
        <li
          className={css({
            alignItems: 'center',
            display: 'flex',
            listStyle: 'none',
            textStyle: 'semantic-text-sm-font-regular',
          })}
          key={item.label}
        >
          <Checkbox
            id={item.label}
            checked={item.isRefined}
            onCheckedChange={() => refine(item.value)}
            mr="semantic-spacing-8"
          />
          <Label
            htmlFor={item.label}
            w="full"
            textStyle={
              item.isRefined
                ? 'semantic-text-sm-font-bold'
                : 'semantic-text-sm-font-regular'
            }
            cursor="pointer"
          >
            {item.label}
          </Label>
          <span
            className={css({
              bg: 'semantic-bg-accent',
              borderRadius: 'semantic-border-radius-medium',
              color: 'semantic-color-inputs-default-text',
              ml: 'semantic-spacing-8',
              px: 'semantic-spacing-4',
            })}
          >
            {item.count}
          </span>
        </li>
      ))}
    </ul>
  )
}
