import NextImage, { type ImageProps } from 'next/image'
import React from 'react'
import { css, cx } from '@/styled-system/css'

export function Image({
  alt,
  blurDataURL = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkAAIAAAoAAv/lPAAAAA==',
  className,
  src,
  ...props
}: Readonly<ImageProps>) {
  return (
    <NextImage
      className={cx(
        css({
          objectFit: 'cover',
          minWidth: '100%',
          minHeight: '100%',
          inset: 0,
        }),
        className,
      )}
      sizes="(max-width: 768px) 100vw, 50vw"
      alt={alt}
      blurDataURL={blurDataURL}
      fill
      placeholder="blur"
      src={src}
      {...props}
    />
  )
}
