'use client'

import { Box, Flex, Grid } from '@/styled-system/jsx'

import { RemoveScroll } from 'react-remove-scroll'
import { useMediaQuery } from 'usehooks-ts'

import {
  Footer,
  Header,
} from '@/global/components/trip-search/filters/sidebar-layout/navigation'

export function SidebarLayout({
  setShowSlideNav,
  showSlideNav,
  children,
}: Readonly<
  React.PropsWithChildren<{
    setShowSlideNav: (show: boolean) => void
    showSlideNav: boolean
  }>
>) {
  const isDesktop = useMediaQuery('(min-width: 1024px)', {
    defaultValue: false,
    initializeWithValue: false,
  })

  return (
    <RemoveScroll className="scroll" enabled={!isDesktop && showSlideNav}>
      <Box
        height="full"
        width={!isDesktop && showSlideNav ? '100%' : 0}
        pos="fixed"
        zIndex={99}
        top={0}
        left={0}
        bg="semantic-bg-overlay"
        opacity={showSlideNav ? 1 : 0}
        transition="opacity 0.5s"
      />
      <Flex
        bg="semantic-bg-default"
        flexDir="column"
        ml={{ base: 'auto', lg: 'semantic-spacing-0' }}
        overflowY={{ base: 'auto', lg: 'visible' }}
        w={{ lg: 304, xl: 440 }}
        h="full"
        transition="all 0.4s ease-out"
        lgDown={{
          pos: 'fixed',
          right: 0,
          top: 0,
          w: showSlideNav ? { base: 'full', xs: 343, md: 432 } : 0,
          zIndex: 100,
        }}
      >
        <Header onClick={() => setShowSlideNav(false)} />
        <Grid
          gridGap={20}
          gridTemplateColumns={'1fr'}
          padding={0}
          p={{
            base: 'semantic-spacing-20',
            md: 'semantic-spacing-40',
            lg: 'semantic-spacing-32',
            xl: 'semantic-spacing-40',
          }}
        >
          {children}
        </Grid>
        <Footer onClick={() => setShowSlideNav(false)} />
      </Flex>
    </RemoveScroll>
  )
}
