'use client'

import { Flex, Box } from '@/styled-system/jsx'

import { useEffect, useState } from 'react'
import { useRange, type UseRangeProps } from 'react-instantsearch'

import { Slider } from '@/components/slider'

import { useSite } from '@/providers/site'

import { currencyFormatter } from '@/utility/currency'

export function RangeSlider(props: Readonly<UseRangeProps>) {
  const { locale } = useSite()
  const { start, range, canRefine, refine } = useRange(props)
  const { min, max } = range
  const [value, setValue] = useState([min, max])

  const from = Math.max(
    min as number,
    Number.isFinite(start[0]) ? (start[0] as number) : (min as number),
  )
  const to = Math.min(
    max as number,
    Number.isFinite(start[1]) ? (start[1] as number) : (max as number),
  )

  useEffect(() => {
    setValue([to, from])
  }, [from, to])

  return (
    <Flex>
      <Slider
        disabled={!canRefine}
        max={max}
        min={min}
        step={1000}
        w="full"
        onValueChange={(nums: number[]) => refine([undefined, nums[1]])}
        value={value as number[]}
      />

      <Box
        alignItems="center"
        border="1px solid var(--colors-semantic-color-inputs-default-border)"
        color="semantic-color-inputs-default-text"
        display="inline-flex"
        h={40}
        ml="semantic-spacing-16"
        px="semantic-spacing-12"
        rounded="semantic-border-radius-small"
      >
        {value[0] && currencyFormatter(value[0], locale)}
      </Box>
    </Flex>
  )
}
