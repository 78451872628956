'use client'

import { Box, Flex } from '@/styled-system/jsx'

import { addYears, setMonth } from 'date-fns'
import { useState } from 'react'
import { Configure } from 'react-instantsearch'

import { SearchFilters } from '@/global/components/trip-search/filters'
import { SearchResults } from '@/global/components/trip-search/results'
import { SearchResultHeader } from '@/global/components/trip-search/results/header'

import { formatDateYearMonth } from '@/utility/algolia/format-search'

import { useSite } from '@/providers/site'

import type { RoomType } from '@/types/algolia/trip-search'

export function Search() {
  const {
    brand: { code },
    locale,
  } = useSite()
  const [roomType, setRoomType] = useState<RoomType>('double')
  const [showSlideNav, setShowSlideNav] = useState(false)

  const searchFilters = `locale:${locale} AND brand:${code} AND NOT hideFromSearch:true`

  const fourYearsFromToday = addYears(new Date(), 4)
  const minDate = formatDateYearMonth(new Date())
  const maxDate = formatDateYearMonth(setMonth(fourYearsFromToday, 0))

  return (
    <Box>
      <Configure distinct filters={searchFilters} hitsPerPage={8} />
      <SearchResultHeader
        roomType={roomType}
        setShowSlideNav={setShowSlideNav}
      />

      <Flex
        flexDir="row"
        minH="100dvh"
        bg="semantic-bg-subtle"
        borderTop={{ lg: '1px solid var(--colors-semantic-border-default)' }}
      >
        <SearchFilters
          roomType={roomType}
          setRoomType={setRoomType}
          setShowSlideNav={setShowSlideNav}
          showSlideNav={showSlideNav}
          minDate={Number(minDate)}
          maxDate={Number(maxDate)}
          hidePriceRange={code?.toLowerCase() !== 'travelmarvel'}
        />

        <SearchResults roomType={roomType} maxDate={Number(maxDate)} />
      </Flex>
    </Box>
  )
}
