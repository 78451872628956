import { Grid } from '@/styled-system/jsx'

import { TripCard } from '@/global/components/trip-card'

import { useHits, type UseHitsProps } from 'react-instantsearch'

import type { RoomType } from '@/types/algolia/trip-search'

export function Hits(
  props: Readonly<
    UseHitsProps & {
      roomType: RoomType
    }
  >,
) {
  const { roomType, ...rest } = props
  const { items } = useHits(rest)

  return (
    <Grid
      gap="semantic-spacing-32"
      gridTemplateColumns={{
        base: '1fr',
        md: '1fr 1fr',
        xl: 'repeat(auto-fill, minmax(382px, 1fr))',
      }}
    >
      {items.map((hit) => {
        const roomPrice = hit.prices?.[roomType]
        const hasPrice = !!roomPrice?.price
        const bookingDisabled = !!hit.isBookingDisabled
        const hasPromo = hasPrice && roomPrice?.basePrice > roomPrice?.price

        return (
          <TripCard
            variant="stacked"
            ctaLabel={'View Tour'}
            duration={hit.durationDays}
            id={'trip-card-' + hit.tripCode}
            journey={hit.journey}
            key={hit.objectID}
            promo={!bookingDisabled && hasPromo ? roomPrice?.promoText : null}
            tag={hit.travelStyles[0]}
            title={hit.title}
            url={hit.pageUrl}
            priceFrom={
              !bookingDisabled && hasPromo
                ? hit.prices![roomType]!.basePrice
                : null
            }
            priceTo={!bookingDisabled && hasPrice ? roomPrice.price : null}
            image={{
              src: hit.thumbnail,
              alt: hit.title,
              as: 'image',
              type: 'bynder',
              description: hit.title,
            }}
          />
        )
      })}
    </Grid>
  )
}
