import { format } from 'date-fns'

export function formatDateYearMonth(date: Date | string) {
  return format(new Date(date), 'yyyyMM')
}

export function formatYearMonthToDateString(
  yearMonth: number,
  locale: string = 'en-AU',
): string {
  const yearMonthStr = yearMonth.toString()
  const year = yearMonthStr.slice(0, 4)
  const month = parseInt(yearMonthStr.slice(4, 6)) - 1 // Convert to zero-based index

  const date = new Date(parseInt(year), month)

  const formatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'short',
  })
  return formatter.format(date)
}

function formatDateRange(dateRange: { from?: string; to?: string }) {
  const { from, to } = dateRange

  if (!from || !to) {
    return null
  }

  const formattedFrom = formatDateYearMonth(from)
  const formattedTo = formatDateYearMonth(to)

  return `date:${formattedFrom} TO ${formattedTo}`
}

// Generate filters for Algolia search
export function generateFilters(filters: Record<string, any>) {
  const filterStrings = Object.keys(filters).map((key) => {
    const value = filters[key as keyof typeof filters]

    switch (key) {
      case 'tripCode':
        return Array.isArray(value) && value.length > 0
          ? `${value.map((v) => `${key}:${v}`).join(' OR ')}`
          : null

      case 'dateRange':
        return formatDateRange(value)

      default:
        return `${key}:"${value}"`
    }
  })

  return filterStrings.filter(Boolean).join(' AND ')
}

// Format cache key for netlify blobs
export function formatCacheKey(searchRequest: Record<string, any>) {
  const flattenedString = Object.entries(searchRequest)
    .map(([key, value]) => {
      const flattenedValue = Array.isArray(value)
        ? value.join(',')
        : String(value)
      return `${key}-${flattenedValue}`
    })
    .join('-')

  const sanitizedString = flattenedString
    .replace(/[^a-zA-Z0-9\s-]/g, '') // Remove non-alphanumeric characters except spaces and dashes
    .replace(/\s+/g, '-') // Replace spaces with dashes
    .replace(/-+/g, '-') // Replace multiple dashes with a single dash

  return sanitizedString
}
