import { Box, Flex } from '@/styled-system/jsx'

import { MdClose } from 'react-icons/md'
import { useInstantSearch, useClearRefinements } from 'react-instantsearch'

import { Button } from '@/components/button'

export function Header({
  onClick,
}: Readonly<React.PropsWithChildren<{ onClick: () => void }>>) {
  return (
    <Box ml="auto" hideFrom="lg" w="full">
      <Flex
        bg="semantic-bg-default"
        borderBottom="1px solid var(--colors-semantic-border-default)"
        justifyContent={'space-between'}
        px="semantic-spacing-24"
        py="semantic-spacing-16"
        textStyle="semantic-text-xl-font-regular"
      >
        Filters
        <Button icon={<MdClose />} size="sm" onClick={onClick} />
      </Flex>
    </Box>
  )
}

export function Footer({ onClick }: { onClick: () => void }) {
  const { results } = useInstantSearch()
  const { refine } = useClearRefinements()

  const numOfResults = results.nbHits

  return (
    <Flex
      bg="semantic-bg-default"
      bottom={0}
      boxShadow="semantic-shadow-lg-reverse"
      gap="semantic-spacing-8"
      hideFrom="lg"
      mt="auto"
      p="semantic-spacing-16"
      pos="sticky"
      zIndex={100}
    >
      <Button css={{ w: 'full' }} variant="tertiary" onClick={refine}>
        Clear all
      </Button>
      <Button css={{ w: 'full' }} variant="primary" onClick={onClick}>
        View {numOfResults} {numOfResults === 1 ? 'trip' : 'trips'}
      </Button>
    </Flex>
  )
}
