'use client'

import { Slot } from '@radix-ui/react-slot'

import * as React from 'react'
import { MdKeyboardArrowRight } from 'react-icons/md'

import { styled } from '@/styled-system/jsx'
import { breadcrumb as breadcrumbRecipe } from '@/styled-system/recipes'

import { createStyleContext } from '@/theme/support/style-context'

const { withProvider, withContext } = createStyleContext(breadcrumbRecipe)

const Root = ({ ...props }) => <nav aria-label="breadcrumb" {...props} />

Root.displayName = 'Breadcrumb'

const List = ({ ...props }: React.ComponentProps<'ol'>) => <ol {...props} />

List.displayName = 'BreadcrumbList'

const Item = ({ ...props }: React.ComponentProps<'li'>) => <li {...props} />

Item.displayName = 'BreadcrumbItem'

const Link = ({
  asChild,
  ...props
}: React.ComponentProps<'a'> & { asChild?: boolean }) => {
  const Comp = asChild ? Slot : 'a'

  return <Comp {...props} />
}

Link.displayName = 'BreadcrumbLink'

const Page = ({ ...props }: React.ComponentProps<'span'>) => (
  <span role="link" aria-disabled="true" aria-current="page" {...props} />
)

Page.displayName = 'BreadcrumbPage'

const Separator = ({ children, ...props }: React.ComponentProps<'li'>) => (
  <li role="presentation" aria-hidden="true" {...props}>
    {children ?? <MdKeyboardArrowRight />}
  </li>
)
Separator.displayName = 'BreadcrumbSeparator'

/**
 * @example
 * ```jsx
 * import {
 *   Breadcrumb,
 *   BreadcrumbItem,
 *   BreadcrumbLink,
 *   BreadcrumbList,
 *   BreadcrumbSeparator,
 *   BreadcrumbPage,
 * } from '@/components/breadcrumb';
 *
 * export const ExampleBreadcrumb = () => {
 *  return (
 *    <Breadcrumb>
 *      <BreadcrumbList>
 *        <BreadcrumbItem>
 *          <BreadcrumbLink href="/">Home</BreadcrumbLink>
 *        </BreadcrumbItem>
 *        <BreadcrumbSeparator />
 *        <BreadcrumbItem>
 *          <BreadcrumbLink href="/components">Components</BreadcrumbLink>
 *        </BreadcrumbItem>
 *        <BreadcrumbSeparator />
 *        <BreadcrumbItem>
 *          <BreadcrumbPage>Hello</BreadcrumbPage>
 *        </BreadcrumbItem>
 *      </BreadcrumbList>
 *    </Breadcrumb>
 * )}
 * ```
 */
const Breadcrumb = withProvider(styled(Root), 'root')

/**
 * @example
 * ```jsx
 * import { Breadcrumb, BreadcrumbList, BreadcrumbItem } from '@/components/breadcrumb'
 *
 * export const ExampleBreadcrumbList = () => {
 *   return (
 *     <Breadcrumb>
 *       <BreadcrumbList>
 *         <BreadcrumbItem>
 *           <BreadcrumbLink href="/">Home</BreadcrumbLink>
 *         </BreadcrumbItem>
 *       </BreadcrumbList>
 *     </Breadcrumb>
 *   );
 * };
 * ```
 */
const BreadcrumbList = withContext(styled(List), 'list')

/**
 * @example
 * ```jsx
 * import { BreadcrumbItem, BreadcrumbLink } from '@/components/breadcrumb';
 *
 * export const ExampleBreadcrumbItem = () => {
 *   return (
 *     <BreadcrumbItem>
 *       <BreadcrumbLink href="/">Home</BreadcrumbLink>
 *     </BreadcrumbItem>
 *   );
 * };
 * ```
 */
const BreadcrumbItem = withContext(styled(Item), 'item')

/**
 * @example
 * ```jsx
 * import { BreadcrumbLink } from '@/components/breadcrumb';
 *
 * export const ExampleBreadcrumbLink = () => {
 *   return (
 *     <BreadcrumbLink href="/">Home</BreadcrumbLink>
 *   );
 * };
 * ```
 */
const BreadcrumbLink = withContext(styled(Link), 'link')

/**
 * @example
 * ```jsx
 * import { BreadcrumbPage } from '@/components/breadcrumb';
 *
 * export const ExampleBreadcrumbPage = () => {
 *   return (
 *     <BreadcrumbPage>Hello</BreadcrumbPage>
 *   );
 * };
 * ```
 */
const BreadcrumbPage = withContext(styled(Page), 'page')

/**
 * @example
 * ```jsx
 * import { BreadcrumbSeparator } from '@/components/breadcrumb';
 *
 * export const ExampleBreadcrumbSeparator = () => {
 *   return <BreadcrumbSeparator />;
 * };
 * ```
 */
const BreadcrumbSeparator = withContext(styled(Separator), 'separator')

export {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
  BreadcrumbSeparator,
}
