'use client'

import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import nextId from 'react-id-generator'

import { CustomDropdown } from '@/global/components/trip-search/custom/month-picker/dropdown'

import styles from './month-picker.module.css'

import 'react-datepicker/dist/react-datepicker.css'
import './overwrite-date-picker.css'

export function Calendar({
  handleDateChange,
  label,
  minDate,
  placement,
  selectedDate,
  setShowCalendar,
  showCalendar,
  yearsArray,
}: Readonly<{
  handleDateChange: (date: Date | null) => void
  label: string
  minDate: Date
  placement: 'left' | 'right'
  selectedDate: Date | null
  setShowCalendar: (show: boolean) => void
  showCalendar: boolean
  yearsArray: Date[]
}>) {
  return (
    <div className={styles.container}>
      <CustomDropdown
        date={selectedDate && format(selectedDate, 'MMM yyyy')}
        label={label}
        onClick={() => setShowCalendar(!showCalendar)}
        showCalendar={showCalendar}
      />
      {showCalendar ? (
        <DatePicker
          calendarClassName={`placement-${placement}`}
          dateFormat="MMM yyyy"
          inline
          minDate={minDate}
          onCalendarClose={() => setShowCalendar(false)}
          onCalendarOpen={() => setShowCalendar(true)}
          onChange={handleDateChange}
          onClickOutside={() => setShowCalendar(false)}
          selected={selectedDate}
          showFourColumnMonthYearPicker
          showMonthYearPicker
          showPopperArrow={false}
          renderCustomHeader={({ changeYear, date }) => (
            <div
              aria-label="year"
              className={styles['year-navigation-wrapper']}
              role="listbox"
            >
              {yearsArray.map((year) => {
                const formattedYear = year.getFullYear()
                const isSelected = date.getFullYear() === formattedYear

                return (
                  <button
                    aria-selected={isSelected}
                    aria-label={`Choose year ${formattedYear}`}
                    className={`${styles['year-navigation-button']} ${
                      isSelected ? styles['year-navigation-button-active'] : ''
                    }`}
                    key={nextId('date-picker-year-')}
                    onClick={() => changeYear(formattedYear)}
                    role="option"
                    type="button"
                  >
                    {formattedYear}
                  </button>
                )
              })}
            </div>
          )}
        />
      ) : null}
    </div>
  )
}
