import {
  useClearRefinements,
  type UseClearRefinementsProps,
} from 'react-instantsearch'

import { Button } from '@/components/button'

export function ClearRefinements(props: Readonly<UseClearRefinementsProps>) {
  const { refine, canRefine } = useClearRefinements(props)

  return canRefine ? (
    <Button
      css={{ p: 'semantic-spacing-0' }}
      size="sm"
      variant="link"
      onClick={refine}
    >
      Clear all
    </Button>
  ) : null
}
