import { css } from '@/styled-system/css'
import { Flex } from '@/styled-system/jsx'

import { MdClose } from 'react-icons/md'
import {
  useCurrentRefinements,
  type UseCurrentRefinementsProps,
} from 'react-instantsearch'

import { Label } from '@/components/label'

import { ClearRefinements } from '@/global/components/trip-search/custom/clear-refinement'

export function CurrentRefinements(
  props: Readonly<UseCurrentRefinementsProps>,
) {
  const { items, refine } = useCurrentRefinements(props)

  return (
    <Flex
      alignItems="center"
      gap="semantic-spacing-16"
      flexWrap="wrap"
      pb={items.length && 'semantic-spacing-24'}
    >
      {items.map((item) => (
        <Flex
          bg="semantic-primary-subtle"
          w="fit-content"
          px="semantic-spacing-12"
          py="semantic-spacing-8"
          gap="semantic-spacing-16"
          key={[item.indexName, item.label].join('/')}
        >
          <Label textStyle="semantic-text-sm-font-bold">{item.label}:</Label>
          {item.refinements.map(
            (refinement) =>
              refinement.label && (
                <button
                  className={css({
                    display: 'inline-flex',
                    color: 'semantic-primary-default',
                    alignItems: 'center',
                    textStyle: 'semantic-text-sm-font-regular',
                    cursor: 'pointer',

                    _hover: {
                      color: 'semantic-primary-highlight',
                    },
                  })}
                  key={refinement.label}
                  type="button"
                  onClick={(event) => {
                    if (isModifierClick(event)) {
                      return
                    }

                    refine(refinement)
                  }}
                >
                  {refinement.label}
                  <MdClose
                    className={css({
                      ml: 'semantic-spacing-4',
                      w: 'semantic-sizing-16',
                      h: 'semantic-sizing-16',
                    })}
                  />
                </button>
              ),
          )}
        </Flex>
      ))}
      <ClearRefinements />
    </Flex>
  )
}

function isModifierClick(event: React.MouseEvent) {
  const isMiddleClick = event.button === 1

  return Boolean(
    isMiddleClick ||
      event.altKey ||
      event.ctrlKey ||
      event.metaKey ||
      event.shiftKey,
  )
}
