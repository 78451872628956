import { Box } from '@/styled-system/jsx'

import { currencyFormatter } from '@/utility/currency'
import { formatYearMonthToDateString } from '@/utility/algolia/format-search'

import { useSite } from '@/providers/site'

import { CurrentRefinements } from '@/global/components/trip-search/custom/current-refinement'
import { Hits } from '@/global/components/trip-search/custom/hits'
import { Pagination } from '@/global/components/trip-search/custom/pagination'
import {
  NoResults,
  NoResultsBoundary,
} from '@/global/components/trip-search/results/no-results'

import type { RoomType } from '@/types/algolia/trip-search'

const refinementLabelMap: Record<string, string> = {
  'prices.double.price': 'Price',
  'prices.single.price': 'Price',
  date: 'Departing',
  durationGroup: 'Duration',
  region: 'Region',
  travelStyles: 'Travel style',
}

export function SearchResults({
  roomType,
  maxDate,
}: Readonly<{
  roomType: RoomType
  maxDate: number
}>) {
  const { locale } = useSite()

  return (
    <Box
      flex={1}
      p={{
        base: 'var(--spacing-semantic-spacing-40) var(--spacing-semantic-spacing-16)',
        md: 'var(--spacing-semantic-spacing-40) var(--spacing-semantic-spacing-24)',
        lg: 'semantic-spacing-32',
        xl: 'semantic-spacing-48',
      }}
    >
      <CurrentRefinements
        transformItems={(items) => {
          items.map((item) => {
            if (item.label in refinementLabelMap) {
              item.label = refinementLabelMap[item.label]
            }

            if (
              item.label === 'Price' &&
              typeof item.refinements?.[0]?.value === 'number'
            ) {
              const refinement = item.refinements[0]

              refinement.label = refinement.label.replace(
                refinement.value as string,
                currencyFormatter(refinement.value as number, locale),
              )
            }

            if (item.label === 'Departing') {
              const dateFrom = item.refinements[0]
              const dateTo = item.refinements[1]

              if (dateFrom) {
                dateFrom.label = `> ${formatYearMonthToDateString(
                  dateFrom.value as number,
                  locale,
                )}`
              }

              if (dateTo) {
                if (dateTo.value === maxDate) {
                  dateTo.label = ''
                  dateTo.value = ''
                  return
                }

                dateTo.label = `< ${formatYearMonthToDateString(
                  dateTo.value as number,
                  locale,
                )}`
              }
            }
          })

          return items
        }}
      />

      <NoResultsBoundary fallback={<NoResults />}>
        <Hits roomType={roomType} />
      </NoResultsBoundary>

      <Pagination />
    </Box>
  )
}
